import axios from 'axios';
import moment from 'moment/moment';

class IntegrationEngineApi {
	constructor() {
		this.axiosInstance = axios.create({
			baseURL:
				process.env.NODE_ENV === 'production'
					? 'https://aei-7.ameroservices.dk/ie-api'
					: 'http://aei.test/ie-api',
			timeout: 60000
		});
	}

	async setToken(token) {
		this.token = token;
		this.axiosInstance.interceptors.request.use(async config => {
			config.headers['Authorization'] = 'Bearer ' + this.token;
			return config;
		});
	}

	async getIntegrations(data) {
		const params = {
			page: data.current,
			limit: data.resultsPerPage
		};
		if (data.searchTerm) {
			params.search = data.searchTerm;
		}

		return await this.axiosInstance.get('/integrations', {
			params
		});
	}

	async getIntegrationById(id) {
		return await this.axiosInstance.get(`/integrations/${id}`);
	}

	async getIntegrationLogsById(id, data) {
		const params = { limit: data.limit, page: data.page };

		if (data.searchValue) {
			params.search = data.searchValue;
		}

		if (data.status) {
			params.status = data.status;
		}

		params.from_date = moment(data.from).toISOString();
		params.status = data.status;

		if (data.type) {
			params.type = data.type;
		}

		if (data.to) {
			params.to_date = moment(data.to).toISOString();
		}

		return this.axiosInstance.get(`/integrations/${id}/logs`, {
			params
		});
	}

	async getIntegrationSynchronizationsById(id, data) {
		const params = { limit: data.limit, page: data.page };

		if (data.searchValue) {
			params.search = data.searchValue;
		}

		if (data.hideEmpty) {
			params.hide_empty = 1;
		} else {
			params.hide_empty = 0;
		}

		params.from_date = moment(data.from).toISOString();

		if (data.to) {
			params.to_date = moment(data.to).toISOString();
		}

		params.sections = 20;

		return this.axiosInstance.get(`/integrations/${id}/synchronizations`, {
			params
		});
	}

	async getIntegrationDataIssuesById(id, data) {
		const params = {};

		if (data.searchValue) {
			params.search = data.searchValue;
		}
		return this.axiosInstance.get(`/integrations/${id}/issues`, {
			params
		});
	}
	async getIntegrationProductsById(id, data) {
		const params = { limit: data.limit, page: data.page };

		if (data.searchValue) {
			params.search = data.searchValue;
		}
		return this.axiosInstance.get(`/integrations/${id}/canonical`, {
			params
		});
	}

	async getProdDataFromControllingWarehouse(id, productNumber) {
		return this.axiosInstance.get(`/integrations/${id}/debugInfo/${productNumber}`);
	}

	async patchIntegrationById(id, data) {
		return await this.axiosInstance.patch(`/integrations/${id}`, data);
	}

	async patchLogDescriptionByHashkey(hashkey, description) {
		return await this.axiosInstance.patch(`/logs/errors/description`, { description, error_hash: hashkey });
	}

	async tagProductForUpdate(id, productNumber) {
		return await this.axiosInstance.post(`/integrations/${id}/canonical/tagforupdate/${productNumber}`);
	}

	async getProductData(id, productNumber) {
		return await this.axiosInstance.get(`integrations/${id}/productData/${productNumber}`);
	}

	async getProductStocks(id, productNumber) {
		return await this.axiosInstance.get(`integrations/${id}/productStocks/${productNumber}`);
	}
}

const integrationEngineApiServiceInstance = new IntegrationEngineApi();

export default integrationEngineApiServiceInstance;
